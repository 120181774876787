import { Route, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable, of, Subject, takeUntil } from 'rxjs';
import jwt_decode, { jwtDecode } from 'jwt-decode';
import { StatehandlerService } from './statehandler.service';
import { UserService } from 'app/core/user/user.service';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MyToastrService } from 'app/_services/toastr.service';
import { BaseRequestService } from 'app/_services/base.service';
@Injectable({
    providedIn: 'root',
})

export class AuthenticationService {
    private _authenticated: boolean = false;
    private readonly _authenticationChanged: BehaviorSubject<boolean> = new BehaviorSubject(this.authenticated);
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _router = inject(Router);
    accessToken: any = '';

    constructor(
        private oauthService: OAuthService,
        private authConfig: AuthConfig,
        private statehandler: StatehandlerService,
        private uService: UserService,
        private router: Router,
        private storageService: StorageService,
        private _httpClient: HttpClient,
        private toast:MyToastrService,
        private _bs:BaseRequestService
    ) {

    }

    public get authenticated(): boolean {
        return this._authenticated;
    }
    updateAuth(state: boolean): void {
        this._authenticated = state;
        if (this._authenticated) {
            const token = this.oauthService.getAccessToken();
            if (token) {
                const user: any = jwtDecode(token);
                user.avatar = 'images/avatars/profile.png';
                user.status = 'online';
                this.uService.userd$ = of(user);
                localStorage.setItem('accessToken', token);
            }
        }
    }

    public get authenticationChanged(): Observable<boolean> {
        return this._authenticationChanged;
    }

    public getOIDCUser(): Observable<any> {
        return from(this.oauthService.loadUserProfile());
    }

    validatetoken(){
        var data = localStorage.getItem('user')
    const httpOptionss = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    }
    this._bs.doRequest(`https://bpcliothosapi.azurewebsites.net/api/generatetoken?`, 'post', data).subscribe((result: any) => {
            if(result.token){
            var usernow = jwtDecode(result.token);
            localStorage.setItem('user', JSON.stringify(usernow))

            setTimeout(() => {
                const redirectURL =
                    // this._activatedRoute.snapshot.queryParamMap.get(
                    //     'redirectURL'
                    // ) || '/dashboard';

                // Navigate to the redirect url
                this._router.navigateByUrl('/dealer-retail-outlets');
            })}
      }, error =>{
        if(error && error.msg){
            
        }
      })
}

    public async authenticate(setState: boolean = true): Promise<boolean> {
        if (localStorage.getItem('showDebugInformation')) {
            this.authConfig.showDebugInformation = true;
        }
        if (localStorage.getItem('disablePKCE')) {
            this.authConfig.disablePKCE = false;
        }
        this.oauthService.configure(this.authConfig);
        this.oauthService.setupAutomaticSilentRefresh();

        this.oauthService.strictDiscoveryDocumentValidation = false;
        await this.oauthService.loadDiscoveryDocumentAndTryLogin();

        this._authenticated = this.oauthService.hasValidAccessToken();

        if (!this.oauthService.hasValidIdToken() || !this.authenticated) {
            const newState = setState ? await this.statehandler.createState().toPromise() : undefined;
            this.oauthService.initCodeFlow(newState);
        }
        this._authenticationChanged.next(this.authenticated);

        return this.authenticated;
    }

    fetchToken(key: string): void {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                APPLICATION: 'IOTPORTAL',
                portalsecretkey: key,
            }),
        };
        this._httpClient
            .get(
                'https://admin-api.bpclcloud9.com/accessKey/userDTO',
                httpOptions
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => {
                if (user.status === 200) {
                    if(user.userDTO.userType === "vendor"){
                        if(user.userDTO.dealerRoId){
                        window.open(`${environment.keycloakURL}/bpclvendor/redirect`, '_self');}
                        else{
                            this.toast.sToast('error', "Missing Vendor details, please contact SO.")
                            setTimeout(() => {
                                window.open('https://metahos.bpclcloud9.com', '_self');
                            }, 2000);
                        }
                        return;
                    }
                    // else if(user.userDTO.userType === 'IFMS'){
                    //     // https://bpclifms.mycybercns.com/sign-in?redirectURL=%2Fassessment
                    //     window.open('https://bpclifms.mycybercns.com/sign-in', '_self');
                    //     return;

                    // }
                    user.userDTO.userPosition = 'HQA'
                    const httpOptionss = {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/json',
                        }),
                    };

                    this._httpClient
                        .post(
                            `${environment.keycloakURL}/generatetoken', user.userDTO`,
                            httpOptionss
                        )
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((apiuser: any) => {
                            if (apiuser.token) {
                                localStorage.setItem('apitoken', apiuser.token)
                                this.accessToken = apiuser.token
                                var usernow = jwtDecode(apiuser.token);
                                localStorage.setItem('user', JSON.stringify(usernow))
                                this.updateAccessKey(user.userDTO);
                                setTimeout(() => {
                                    this.router.navigateByUrl('/dealer-retail-outlets');
                                })
                            }
                        })

                } else {
                    localStorage.clear(); sessionStorage.clear();
                    this.router.navigateByUrl('/sign-in');
                }
            }, (error: any) => {
                console.log(error);
                this.router.navigateByUrl('/sign-in?e=Unauthorized');
            });
    }

    check(): Observable<boolean> {
        if (this.authenticated) {
            return of(true);
        } else {
            this.authenticate();
        }
        return of(false);
    }

    updateAccessKey(key: any): void {
        this.accessToken = key.jwtToken;
        key.id = key.userId;
        key.name = key.userName;
        key.email = key.userEmail;
        key.avatar = 'images/avatars/profile.png';
        key.status = 'online';
        this._authenticated = true;
        // this.oauthService.setItem('access_token', key.jwtToken)
        setTimeout(() => {
            const redirectURL = '/dealer-retail-outlets';
            // Navigate to the redirect url
            this.router.navigateByUrl(redirectURL);
            return;
        })
    }

    public signOut(): void {
        const logoutUrl = `${environment.keycloakURL}/auth/realms/${environment.realmName}/protocol/openid-connect/logout`;
        const idToken = this.oauthService.getIdToken();
        this.oauthService.getIdToken()
        const postLogoutRedirectUri = encodeURIComponent(window.location.origin);
        const logoutRedirectUrl = `${logoutUrl}?id_token_hint=${encodeURIComponent(idToken)}&post_logout_redirect_uri=${postLogoutRedirectUri}`;
        this.oauthService.logOut();
        this.storageService.clear();
        this._authenticated = false;
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = logoutRedirectUrl;
    }
}
