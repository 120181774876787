import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { BaseRequestService } from "../_services/base.service";
import { LoaderService } from "../_services/loader.service";
import { jwtDecode } from "jwt-decode";
import { UserService } from "../core/user/user.service";

export abstract class StatehandlerProcessorService {
    public abstract createState(url: string): Observable<string | undefined>;
    public abstract restoreState(state?: string): void;
}

@Injectable()
export class StatehandlerProcessorServiceImpl
    implements StatehandlerProcessorService {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(private location: Location, private _router: Router,
        private oauth: OAuthService, private bs: BaseRequestService,
        private ls: LoaderService,
        private userService: UserService) { }

    public createState(url: string): Observable<string> {
        let s = this.location.prepareExternalUrl(url);
        const externalUrl = (s.indexOf('sign-in?tenant_name') > -1) ? '/sign-in' : s;
        const urlId = uuidv4();
        sessionStorage.setItem(urlId, externalUrl);
        return of(urlId);
    }

    public restoreState(state?: string): void {
        if (state === undefined) {
            return;
        }
        let url = sessionStorage.getItem(state);
        if (url === null) {
            return;
        }
        /*if (url === '/sign-in' || url === '/sign-out') {
            url = '/auth-grant';
        }*/
        const data: any = this.setup();
        // window.location.href = window.location.origin + url;
    }

    async setup(): Promise<any> {
        try {
            if (this.oauth.hasValidAccessToken()) {
                const token = this.oauth.getAccessToken();
                if (token) {
                    const user: any = jwtDecode(token);
                    user.avatar = 'images/avatars/profile.png';
                    user.status = 'online';
                    this.userService.userd$ = of(user);
                    localStorage.setItem('accessToken', token);
                }
            }
        } catch (error) {
            throw error;
        }
    }
}
